import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import Img from "gatsby-image"

export default function Blog({ data }) {
	const sources = [
		data.mobileImageCM.childImageSharp.fluid,
		{
			...data.desktopImageCM.childImageSharp.fluid,
			media: `{min-width:768px}`,
		}
	]
	return (
		<Layout>

			<article className="blog">
				<header className="entry-header">
					<h1 className="entry-title">Scripting with Windows and Git</h1>
					<div className="entry-meta">
						<span className="sep">Posted on </span>
						<time className="entry-date">July 22, 2017</time>
					</div>
				</header>
				<div className="entry-content">
					<p>Ever need to write a script to check out a git repo?  Bypassing the credentials prompt?  Securely? On Windows?</p>
					<p>I did!  And it was painful!</p>
					<p>I started going down a deep dark rabbit hole of hacks that stored my credentials in plain text and did not properly work in Windows.  Until I discovered a neat little tool called <a href="https://github.com/Microsoft/Git-Credential-Manager-for-Windows">Git Credential Manager for Windows</a>&nbsp;(GCM).</p>

					<h2>Git Credential Manager for Windows</h2>
					<p>The GCM is a credential helper for Git that securely stores our git credentials using the Windows Credential Manager.</p>
					<p>When making an initial git request, the GCM will prompt us for our git credentials and save them.  All further git requests will reuse these credentials.  We won't need to re-enter them ever again.</p>


					<p>To see if GCM is currently installed, simply run:</p>
					<pre>git credential-manager version</pre>

					<p>To setup GCM as our default credential helper, ensure our git configuration is using:</p>
					<pre>credential.helper=manager</pre>


					<p>To view our git configuration (project, global, and system) run:</p>
					<pre>
						git config -l <br />
						git config -l –global <br />
						git config -l –system
					</pre>

					<p>And set at least one with:</p>
					<pre>
						git config –global –unset credential.helper <br />
						git config –global –add credential.helper manager
					</pre>

					<p>Finally, after making our first git request and entering our git credentials, we can verify our credentials are stored in the Windows Credential Manager by going to Control Panel &gt; Credential Manager</p>

					<div><Img fluid={sources} alt="Credential Manager" /></div>

					<h2>Programmatically store Git Credentials</h2>
					<p>Now that GCM is properly configured, our scripts can make git requests, bypassing the credential prompts.</p>
					<p>But what if we want to set those initial git credentials programmatically?</p>

					<p>We can with:</p>

					<pre>
						cmdkey /generic:LegacyGeneric:target=git:https://github.com /user:$gitUsername /pass:"${'{gitToken}'}"
					</pre>

					<h2>Powershell Script</h2>
					<p>Tying everything together, here's our powershell script that will store our git credentials and run git fetch.</p>

					<pre>
						gitUsername="username" <br />
						gitToken="token" <br />
						cmdkey /generic:LegacyGeneric:target=git:https://github.com /user:$gitUsername /pass:"${'{gitToken}'}" <br />
						cd project_repo <br />
						git fetch
					</pre>

					<p>Success!</p>

					<p>Almost?  Remember to edit the first two lines to fetch those credentials securely.  Don't leave them in plain text.</p>

					<p>And if you are using github, setup a <a href="https://help.github.com/articles/creating-a-personal-access-token-for-the-command-line/">personal token</a> instead of using your password.</p>

					<p>I'll leave this up to you :)</p>

					<p>Happy Coding!</p>
				</div>
			</article>
		</Layout>
	)
}

export const query = graphql`
query {
	mobileImageCM: file(relativePath: { eq: "blogs/git-scripting-on-windows/credential-manager.jpg" }) {
		childImageSharp {
			fluid(maxWidth: 680, quality: 100) {
				...GatsbyImageSharpFluid
				...GatsbyImageSharpFluidLimitPresentationSize
			}
		}
	}
	desktopImageCM: file(
		relativePath: { eq: "blogs/git-scripting-on-windows/credential-manager.jpg" }
	) {
		childImageSharp {
			fluid(maxWidth: 1200, quality: 100) {
				...GatsbyImageSharpFluid
				...GatsbyImageSharpFluidLimitPresentationSize
			}
		}
	}
}`
